import Joi from 'joi';

const settings = {
	create: {
		name: Joi.string()
			.min(2)
			.required(),
		key: Joi.string()
			.min(2)
			.required(),
		value: Joi.number().required(),
		active: Joi.number()
	}
};

export default settings;
