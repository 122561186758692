<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<p class="info__address">{{ setting.name }}</p>
			<p>
				<span :class="isActive">
					{{ setting.active === 1 ? 'Active' : 'Inactive' }}
				</span>
			</p>
			<p>{{ setting.value }}</p>
			<p>{{ setting.key }}</p>
			<p>
				<router-link
					:to="{ name: 'setting-detail', params: { id: setting.id } }"
					class="btn btn__primary"
					id="settingDetail"
				>
					View Detail
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SettingItem',
	props: {
		settingData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			setting: this.settingData,
			itemData: '',
			mapStatus: ''
		};
	},
	computed: {
		isActive() {
			return [this.setting.active === 1 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'];
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 2fr 1fr 1fr 1fr 0.4fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>
