<!-- eslint-disable max-len -->
<template>
	<div>
		<template v-if="loadingSettings">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3>Setting</h3>
				</div>
				<div class="d-flex">
					<button
						class="btn btn__primary"
						type="button"
						name="button"
						data-toggle="modal"
						data-target="#settingModal"
						id="addSettingModal"
					>
						<span class="btn__icon">
							<svg width="24" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8 5H5V2H3v3H0v2h3v3h2V7h3V5zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 0 18 0c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86 0 1.07-.34 2.04-.9 2.86.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 0 13 0c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16C20.45 8.89 21 9.82 21 11v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 8c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"
									fill="#fff"
								/>
							</svg>
						</span>
						Add Setting
					</button>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<p>#</p>
						<p>Name</p>
						<p>Status</p>
						<p>Value</p>
						<p>Key</p>
						<p>Actions</p>
					</div>
					<div class="contacts__body">
						<template v-if="settings.length > 0">
							<SettingItem v-for="setting in settings" :key="setting.id" :settingData="setting" />
						</template>
						<template v-else-if="settings.length === 0">
							<p class="mt-5 pl-5">No Setting found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading Settings, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
		</template>
		<AddSetting />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import AddSetting from './AddSetting';
import Pagination from '../../components/Pagination';
import SettingItem from './SettingItem';

export default {
	name: 'Setting',
	components: {
		Loader,
		AddSetting,
		Pagination,
		SettingItem
	},
	data() {
		return {
			loadingSettings: true,
			offset: 0,
			limit: 10,
			baseUrl: 'settings',
			total: 0
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllSettings([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (value === 'success') {
				this.loadingSettings = false;
				this.total = this.totalSettings;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllSettings([this.limit, this.offset]);
		}
	},
	computed: {
		...mapGetters({
			settings: 'getSettings',
			status: 'getStatus',
			totalSettings: 'getTotalSettings'
		})
	},
	methods: {
		...mapActions(['fetchAllSettings'])
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 2fr 1fr 1fr 1fr 0.4fr !important;
}
</style>
