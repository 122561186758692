<template>
	<div
		id="settingModal"
		aria-labelledby="contactModalTitle"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="userModalTitle">Add Setting</h3>
					<button
						@click.prevent="clearErrors()"
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div class="modal-body modal__body">
					<form class="modal__form" method="post" @submit.prevent="addSetting">
						<template v-if="getErrorLog.message">
							<div class="invalid-feedback alert alert-danger text-center" role="alert">
								{{ getErrorLog.message }}
							</div>
						</template>
						<div class="form-row">
							<div class="form-group col-md-12">
								<label class="modal__label" for="name">Name</label>
								<input
									:class="[{ 'is-invalid': errors.name }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.name"
									name="name"
									value=""
									type="text"
									class="form-control"
									id="name"
									placeholder="Name"
								/>
								<div class="invalid-feedback">
									{{ errors.name }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="key">Key</label>
								<input
									:class="[{ 'is-invalid': errors.key }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.key"
									name="key"
									value=""
									type="text"
									class="form-control"
									id="key"
									placeholder="Key"
								/>
								<div class="invalid-feedback">
									{{ errors.key }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="value">Value</label>
								<input
									:class="[{ 'is-invalid': errors.value }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.value"
									name="value"
									value=""
									type="number"
									class="form-control"
									id="value"
									placeholder="Value"
								/>
								<div class="invalid-feedback">
									{{ errors.value }}
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal__footer col-md-12 text-center">
					<button
						@click.prevent="clearErrors()"
						class="btn btn--md"
						type="button"
						data-dismiss="modal"
						aria-label="close"
						name="button"
					>
						Cancel
					</button>
					<button
						@click.prevent="addSetting"
						class="btn btn--md btn__primary"
						type="button"
						v-bind="{ disabled: loading }"
						name="button"
						id="addSetting"
					>
						Add Setting
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import settings from '../../utils/validations/settings';

export default {
	name: 'AddSetting',
	mixins: [form],
	data() {
		return {
			loading: false,
			itemData: {},
			errors: {}
		};
	},
	computed: {
		...mapGetters(['getStatus', 'getErrorLog'])
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.clearFormField();
			}
		}
	},
	methods: {
		...mapActions(['createSetting']),
		addSetting() {
			this.validate(this.itemData, settings.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			this.loading = true;
			this.createSetting(this.itemData);
			this.loading = false;
			return true;
		}
	}
};
</script>
